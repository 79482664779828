import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import CollectionPage from './pages/CollectionPage';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} /> // La raíz será la HomePage
          <Route path="/share/collections" element={<CollectionPage />} /> // Colecciones bajo /share/collections
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;