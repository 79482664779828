import { signInAnonymously } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

export const signInAnonymouslyUser = async () => {
  try {
    const userCredential = await signInAnonymously(auth);
    return userCredential.user;
  } catch (error) {
    console.error("Error signing in anonymously:", error);
    throw error;
  }
};