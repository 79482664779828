import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAxpk25L5LIZAf65PDm5l7J6LoZjQIKGD0",
  authDomain: "lastpage-a0b9a.firebaseapp.com",
  projectId: "lastpage-a0b9a",
  storageBucket: "lastpage-a0b9a.appspot.com",
  messagingSenderId: "544490217382",
  appId: "1:544490217382:web:ece53d7364f1af3ea1b967"
};


// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Exporta Firestore y Auth
export const db = getFirestore(app);
export const auth = getAuth(app);