// Define types for Page and Collection
interface Page {
  title: string;
  url: string;
  created_at: number;
}

interface Owner {
  username: string;
}

export interface Collection {
  name: string;
  owner: Owner;
  pages: Page[];
}

// Función para ordenar las páginas por `created_at`
export const sortPagesByCreatedAt = (pages: Page[]): Page[] => {
  return pages.sort((a, b) => {
    const dateA = a.created_at;
    const dateB = b.created_at;
    return dateB - dateA; // Orden descendente por fecha
  });
};

// Función para obtener una colección y sus páginas ordenadas
export const getSortedCollection = async (getCollectionById: any, ownerId: string, collectionId: string): Promise<Collection | undefined> => {
  try {
    const collectionData: Collection | undefined = await getCollectionById(ownerId, collectionId);

    if (collectionData) {
      collectionData.pages = sortPagesByCreatedAt(collectionData.pages);
    }

    return collectionData;
  } catch (error) {
    console.error("Error fetching or sorting collection:", error);
    throw error;
  }
};