import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { signInAnonymouslyUser } from '../auth/anonymousAuth';
import { getCollectionById } from '../services/collectionService';
import { getSortedCollection, Collection } from '../pages/adapters/CollectionAdapter'; // Importa la nueva función
import LinkPreview from '../components/LinkPreview';
import { ClipLoader } from 'react-spinners';
import { MdNoEncryptionGmailerrorred } from "react-icons/md";
import { formatDistanceToNow } from 'date-fns'; 
import { Timestamp } from 'firebase/firestore';

const CollectionPage: React.FC = () => {
  const [collection, setCollection] = useState<Collection | null>(null); 
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null); 

  const [searchParams] = useSearchParams();
  const collectionId = searchParams.get('id');
  const ownerId = searchParams.get('owner');

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        await signInAnonymouslyUser();

        if (!collectionId || !ownerId) {
          throw new Error('Faltan los parámetros id o owner en la URL.');
        }

        const sortedCollectionData = await getSortedCollection(getCollectionById, ownerId, collectionId);
        setCollection(sortedCollectionData ?? null);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCollection();
  }, [collectionId, ownerId]); 

  const getDateFormat = (created_at: number | Timestamp) => {
    const createdAt = created_at instanceof Timestamp 
    ? created_at.toDate() 
    : new Date(created_at);

    return formatDistanceToNow(createdAt, { addSuffix: true });
  }

  if (loading) {
    return (
      <div style={styles.spinnerContainer}>
        <ClipLoader color="#49EFFE" size={40} /> 
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.IconCentered}>
        <MdNoEncryptionGmailerrorred size={40} color="#FF5105" style={styles.icon} />
        <p style={styles.errorText}>{error}</p>
      </div>
    );
  }

  if (!collection || collection.pages.length === 0) {
    return <div style={styles.centered}>No pages found in this collection.</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{collection.name}</h1>
      <h6 style={styles.subtitle}>Collection by @{collection.owner.username}</h6>
      <ul style={styles.grid}>
        {collection.pages.map((page, index) => (
          <li key={index} style={styles.pageItem}>
            {page.url ? (              
              <LinkPreview url={page.url} title={page.title} created_at_relative={getDateFormat(page.created_at)}/>
            ) : (
              <div>No URL available</div>
            )}
          </li>
        ))}
      </ul>

      <footer style={styles.footer}>
        <img
          src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/f1/33/70/f1337012-ead4-787e-f1db-f5515c949e57/AppIcon-0-0-1x_U007epad-0-8-0-85-220.png/540x540bb.jpg"
          alt="LastPage: Link Manager"
          style={styles.logo}
        />
        <p style={styles.footerText}>
        Powered by LastPage &copy; 2024. All rights reserved.
      </p>
      </footer>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#121212',
    color: '#EFF7F3',
    position: 'relative' as const, // Asegura que los elementos absolutos estén relativos al contenedor
  },
  title: {
    marginBottom: '10px',
    textAlign: 'center' as const,
    fontWeight: 'bold'
  },
  subtitle: {
    marginBottom: '10px',
    textAlign: 'center' as const,
    color: '#808080',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    textAlign: 'center' as const,
  },
  // Definimos un estilo de grid
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Hace columnas automáticas que se ajustan al tamaño disponible
    gridGap: '20px', // Espacio entre los elementos de la grid
    padding: 0,
    listStyle: 'none',
    width: '100%',
    maxWidth: '100%', // Limitar el ancho máximo al 100%
    margin: '0 auto', // Centrar la grid
  },
  pageItem: {
    display: 'flex',
    justifyContent: 'center', // Centrar el contenido de cada preview
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box' as const, // Asegura que el padding no cause desbordamiento
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    width: '100%', // Hace que toda la fila sea clickable
  },
  pageDetails: {
    flex: 1, // Fills the remaining space
    display: 'flex',
    flexDirection: 'column' as const, // Elimina el problema con flexDirection
  },
  pageTitle: {
    marginBottom: '5px', // Separación de 5px entre el título y el subtítulo
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limita a 2 líneas
    WebkitBoxOrient: 'vertical' as const, // Especifica 'vertical' como valor específico
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // Contenedor del spinner
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px', // Establece la altura del contenedor del spinner
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#060606',
    width: '100%',
    color: '#EFF7F3',
  },
  logo: {
    width: '30px',
    height: '30px',
    borderRadius: '22%',
    overflow: 'hidden',
    display: 'inline-block',
    marginRight: '10px', // Espacio entre el logo y el texto
  },
  footerText: {
    fontSize: '14px',
    color: '#EFF7F3',
  },
  IconCentered: {
    display: 'flex',
    flexDirection: 'column' as const,  // Apilar los elementos en columna
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as const,
    height: '100vh',  // O el alto que necesites
  },
  icon: {
    marginBottom: '10px', // Espacio entre el icono y el texto
  },
  errorText: {
    fontSize: '18px',
    color: '#EFF7F3',
  },
};

export default CollectionPage;