import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/HomePage.css'; // Importar los estilos

const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>LastPage - Available on the App Store</title>
        <meta name="facebook-domain-verification" content="yagfqqkfsni8h8abxwfzdlgwlg906l" />
        {/* Smart App Banner Meta Tag */}
        <meta name="apple-itunes-app" content="app-id=6593660555" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <div className="icon-container">
            <a
              href="https://apps.apple.com/us/app/lastpage-link-manager/id6593660555?itscg=30200&amp;itsct=apps_box_appicon"
              style={{
                width: '170px',
                height: '170px',
                borderRadius: '22%',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <img
                src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/f1/33/70/f1337012-ead4-787e-f1db-f5515c949e57/AppIcon-0-0-1x_U007epad-0-8-0-85-220.png/540x540bb.jpg"
                alt="LastPage: Link Manager"
                style={{
                  width: '170px',
                  height: '170px',
                  borderRadius: '22%',
                  overflow: 'hidden',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              />
            </a>
          </div>
          <h1>LastPage</h1>
          <p>The best way to organize and share your links.</p>
          <div className="app-link">
            <a
              href="https://apps.apple.com/us/app/lastpage-link-manager/id6593660555?itsct=apps_box_badge&amp;itscg=30200"
              className="app-link"
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1724889600"
                alt="Download on the App Store"
              />
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="feature-icon mb-4">
                <i className="bi bi-bookmark-fill"></i>
              </div>
              <h4>Save Your Links</h4>
              <p>With LastPage, you can save all your important links in one place.</p>
            </div>
            <div className="col-md-4">
              <div className="feature-icon mb-4">
                <i className="bi bi-shield-lock-fill"></i>
              </div>
              <h4>Organize with Collections</h4>
              <p>Organize your links into private or open collections to share.</p>
            </div>
            <div className="col-md-4">
              <div className="feature-icon mb-4">
                <i className="bi bi-bell-fill"></i>
              </div>
              <h4>Custom Notifications</h4>
              <p>Receive custom reminders for your most important links.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="text-center py-4">
        <p>&copy; 2024 LastPage. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;