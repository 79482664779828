import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchMetadata } from '../services/fetchMetadataService'; // Importa la función de fetch

interface Metadata {
  title: string;
  description: string;
  created_at_relative: string;
  image: string;
  siteName: string;
}

interface LinkPreviewProps {
  url: string;
  title: string;
  created_at_relative: string;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({ url, title, created_at_relative }) => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMetadata = async () => {
      try {
        const metadata = await fetchMetadata(url, created_at_relative);
        setMetadata(metadata);
      } catch (err) {
        setError('Failed to fetch metadata');
      } finally {
        setLoading(false);
      }
    };

    loadMetadata();
  }, [url]);

  if (loading) {
    return (
      <div style={styles.spinnerContainer}>
        <ClipLoader color="#49EFFE" size={40} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={styles.link}>
      <div style={styles.container}>
        {metadata && (
          <>
            <div style={styles.imageContainer}>
              <img src={metadata.image} alt={metadata.title} style={styles.image} />
            </div>
            <div style={styles.details}>
              <h3 style={styles.title}>{metadata.title}</h3>
              <p style={styles.description}>{metadata.description}</p>
              <p style={{...styles.description, ...styles.siteName}}>from {metadata.siteName}</p>
            </div>
          </>
        )}
      </div>
    </a>
  );
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'inherit', // Mantener el estilo de texto del link como el del contenedor
    width: '100%', // Hace que todo el link ocupe el 100% del contenedor
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as const, // Cambiar a columna para apilar imagen encima del texto
    padding: '10px',
    width: '100%', // Asegura que el contenedor ocupe el 100% del ancho disponible
    maxWidth: '100%', // Limita el ancho máximo al 100%
    paddingLeft: '5%', // Padding a la izquierda
    paddingRight: '5%', // Padding a la derecha
    textDecoration: 'none', // Evitar subrayado del link
    color: 'inherit',
    transition: 'transform 0.2s', // Animación en hover
    boxSizing: 'border-box' as const, // Asegura que el padding no cause desbordamiento
    margin: '0 auto', // Centra el contenido horizontalmente
  },
  imageContainer: {
    width: '100%', // La imagen ocupa todo el ancho del contenedor
    paddingTop: '56.25%', // Relación 16:9 (9 / 16 = 0.5625 o 56.25%)
    position: 'relative' as const,
    overflow: 'hidden',
    borderRadius: '3%',
    marginBottom: '10px', // Añadir margen debajo de la imagen para separación
  },
  image: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
    borderRadius: '5px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    width: '100%', // Asegurar que los detalles usen todo el ancho disponible
    boxSizing: 'border-box' as const, // Asegura que el padding no cause desbordamiento
  },
  title: {
    fontSize: '16px',
    margin: '0 0 2px 0', // Separación mínima
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limita a un máximo de 2 líneas
    WebkitBoxOrient: 'vertical' as const,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2em', // Altura de línea ajustada
    maxWidth: '100%', // Asegura que el título ocupe el ancho completo disponible
  },
  description: {
    fontSize: '14px',
    margin: '0 0 4px 0', // Separación mínima
    color: '#808080',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limita a un máximo de 2 líneas
    WebkitBoxOrient: 'vertical' as const,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2em', // Altura de línea ajustada
    maxWidth: '100%', // Asegura que la descripción ocupe el ancho completo disponible
  },
  siteName: {
    color: '#606060'
  },
  // Contenedor del spinner
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px', // Establece la altura del contenedor del spinner
  },
};

export default LinkPreview;