import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

// Define una interfaz para el tipo de datos que esperas recibir
interface Collection {
  name: string;
  owner: Owner;
  pages: Page[];
}

interface Owner {
  username: string
}

interface Page {
  title: string;
  url: string;
  created_at: number;
}

// Cambia `ownerId` y `collectionId` a tipo `string`, y define que la función retorna una Promise de tipo `Collection | undefined`.
export const getCollectionById = async (ownerId: string, collectionId: string): Promise<Collection | undefined> => {
  try {
    // Usa `doc` para referenciar el documento en Firestore
    const collectionRef = doc(db, 'users', ownerId, 'collections', collectionId);

    // Obtén el documento usando `getDoc`
    const collectionDoc = await getDoc(collectionRef);
    
    if (!collectionDoc.exists()) {
      throw new Error("Collection not found");
    }

    // Retorna los datos tipados como `Collection`
    return collectionDoc.data() as Collection;
  } catch (error) {
    console.error("Error fetching collection:", error);
    throw error;
  }
};